import * as React from 'react';

const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={27}
    fill={'black'}
    viewBox="0 0 276.000000 259.000000"
    preserveAspectRatio="xMidYMid meet"
    style={{ marginLeft: '-5px', marginTop: '-6px', width: '30px' }}
    {...props}
  >
    <g
      transform="translate(0.000000,259.000000) scale(0.100000,-0.100000)"
      fill={props?.color || '#000000'}
      stroke="none"
    >
      <path d="M690 2386 c-63 -14 -161 -47 -189 -65 -11 -7 -10 -14 5 -37 10 -16 18 -30 19 -31 0 -1 41 12 90 30 84 29 99 31 220 31 116 0 139 -3 210 -28 91 -31 148 -62 219 -118 71 -56 101 -53 196 18 86 65 134 88 240 115 144 36 316 20 453 -44 149 -70 255 -174 328 -323 54 -111 72 -203 65 -329 -8 -160 13 -145 -206 -145 l-187 0 -38 91 c-45 106 -72 132 -112 108 -20 -11 -38 -46 -82 -166 -31 -83 -58 -154 -62 -157 -3 -3 -24 97 -47 222 -35 186 -46 230 -63 245 -55 45 -81 13 -120 -144 -16 -68 -34 -141 -40 -161 l-10 -38 -146 0 -145 0 6 -22 c3 -13 6 -31 6 -40 0 -16 14 -18 151 -18 l151 0 28 28 c22 22 33 50 51 130 13 56 26 102 29 102 3 0 23 -95 45 -212 40 -217 52 -248 93 -248 36 0 53 28 113 190 33 91 62 166 64 168 2 2 20 -28 39 -66 20 -39 41 -74 48 -79 7 -4 98 -10 201 -13 l189 -5 -31 -59 c-17 -32 -49 -81 -72 -110 -22 -28 -268 -276 -546 -550 l-505 -499 -314 314 -313 313 -58 13 c-32 6 -64 12 -71 12 -19 1 704 -712 734 -723 15 -6 34 -6 49 0 36 13 1046 1013 1100 1089 55 76 109 189 132 275 27 100 24 291 -5 395 -75 260 -269 450 -537 527 -65 18 -104 22 -205 22 -108 -1 -136 -4 -210 -28 -94 -31 -215 -93 -265 -137 l-33 -29 -48 39 c-74 58 -130 87 -233 121 -114 38 -272 49 -381 26z" />
      <path d="M311 2184 c-176 -166 -251 -350 -238 -589 12 -212 85 -382 227 -526 61 -61 94 -85 170 -123 140 -68 199 -81 365 -80 114 1 152 5 205 22 l64 21 24 -22 c33 -30 53 -72 61 -125 7 -51 31 -74 59 -56 28 19 21 109 -13 172 -15 28 -37 60 -49 71 l-22 21 30 87 c27 80 30 99 30 218 0 109 -4 140 -22 189 -53 143 -158 250 -322 328 -252 119 -309 150 -353 193 -65 62 -81 97 -82 183 -2 73 -10 91 -42 92 -6 0 -48 -34 -92 -76z m59 -95 c0 -38 49 -116 105 -167 34 -32 92 -69 148 -96 51 -24 144 -69 209 -100 236 -113 329 -254 315 -480 -6 -93 -40 -226 -58 -226 -27 0 -195 125 -277 207 -51 51 -98 93 -105 93 -19 0 -37 -21 -37 -43 0 -29 171 -194 264 -257 76 -50 78 -54 55 -62 -44 -17 -195 -21 -276 -8 -265 42 -449 207 -534 480 -20 62 -24 97 -24 205 0 192 34 291 146 425 49 60 69 68 69 29z" />
    </g>
  </svg>
);
export default SVGComponent;
