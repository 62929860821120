import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { uniqBy } from 'lodash';

import { HealthValueRange } from 'features';
import ResultLineGraph from 'pages/diagnosticReport/components/lineGraph/ResultLineGraph';

// import HeartHandIcon from 'assets/new/heartHand';
import NormalIcon from 'assets/range-icons/normal.png';
import HighIcon from 'assets/range-icons/high.png';
import LowIcon from 'assets/range-icons/low.png';
import infoBubbleSlantSvg from 'assets/new/infoBubbleSlant.svg';
import DetectedIcon from 'assets/range-icons/detected.png';
import NotDetectedIcon from 'assets/range-icons/not-detected.png';

import './biomarkerSection.scss';

const values = {
  borderline: {
    name: 'Borderline',
    color: 'orange',
    colorCode: '#ffaf3e',
    icon: HighIcon,
  },
  desirable: {
    name: 'Desirable',
    color: 'green',
    colorCode: '#00c48c',
    icon: NormalIcon,
  },
  high: { name: 'High', color: 'red', colorCode: '#ff6666', icon: HighIcon },
  microalbuminuria: {
    name: 'Microalbuminuria',
    color: 'red',
    colorCode: '#ff6666',
    icon: HighIcon,
  },
  macroalbuminuria: {
    name: 'Macroalbuminuria',
    color: 'red2',
    colorCode: '#ff3a3a',
    icon: HighIcon,
  },
  criticalhigh: {
    name: 'Very High',
    color: 'red2',
    colorCode: '#ff3a3a',
    icon: HighIcon,
  },
  veryhigh: {
    name: 'Very High',
    color: 'red2',
    colorCode: '#ff3a3a',
    icon: HighIcon,
  },
  verylow: {
    name: 'Very Low',
    color: 'orange',
    colorCode: '#ffd443',
    icon: LowIcon,
  },
  lowrisk: {
    name: 'Low Risk',
    color: 'greenish2',
    colorCode: '#12b001',
    icon: NormalIcon,
  },
  averagerisk: {
    name: 'Average Risk',
    color: 'green',
    colorCode: '#00c48c',
    icon: NormalIcon,
  },
  highrisk: {
    name: 'High Risk',
    color: 'red',
    colorCode: '#ff6666',
    icon: HighIcon,
  },
  low: { name: 'Low', color: 'yellow', colorCode: '#ffd443', icon: LowIcon },
  optimal: {
    name: 'Optimal',
    color: 'greenish',
    colorCode: '#12b001',
    icon: NormalIcon,
  },
  suboptimal: {
    name: 'subOptimal',
    color: 'yellow',
    colorCode: '#ffd443',
    icon: LowIcon,
  },
  normal: {
    name: 'Normal',
    color: 'green',
    colorCode: '#00c48c',
    icon: NormalIcon,
  },
  sufficient: {
    name: 'Sufficient',
    color: 'green',
    colorCode: '#00c48c',
    icon: NormalIcon,
  },
  ideal: {
    name: 'Ideal',
    color: 'greenish2',
    colorCode: '#12b001',
    icon: NormalIcon,
  },
  acceptable: {
    name: 'Acceptable',
    color: 'green',
    colorCode: '#00c48c',
    icon: DetectedIcon,
  },
  unacceptable: {
    name: 'Unacceptable',
    color: 'red',
    colorCode: '#ff6666',
    icon: NotDetectedIcon,
  },
  negative: {
    name: 'Negative',
    color: 'green',
    colorCode: '#00c48c',
    icon: NotDetectedIcon,
  },
  positive: {
    name: 'Positive',
    color: 'red',
    colorCode: '#ff6666',
    icon: DetectedIcon,
  },
  abnormal: {
    name: 'Abnormal',
    color: 'red',
    colorCode: '#ff6666',
    icon: HighIcon,
  },
  'non-reactive': {
    name: 'Non-Reactive',
    color: 'green',
    colorCode: '#00c48c',
    icon: NotDetectedIcon,
  },
  reactive: {
    name: 'Reactive',
    color: 'red',
    colorCode: '#ff6666',
    icon: DetectedIcon,
  },
  nil: {
    name: 'Nil',
    color: 'green',
    colorCode: '#00c48c',
    icon: NotDetectedIcon,
  },
  trace: {
    name: 'Trace',
    color: 'orange',
    colorCode: '#ffaf3e',
    icon: DetectedIcon,
  },
  deficient: {
    name: 'Trace',
    color: 'red',
    colorCode: '#ff6666',
    icon: DetectedIcon,
  },
  insufficient: {
    name: 'Trace',
    color: 'orange',
    colorCode: '#ffaf3e',
    icon: DetectedIcon,
  },
};

const BiomarkerSection = ({ observation, graphData }) => {
  const [obsRange, setObsRange] = useState(null);
  const [graphPoints, setGraphPoints] = useState(null);
  const [graphUnit, setGraphUnit] = useState(null);
  const [average, setAverage] = useState(0);
  const [latest, setLatest] = useState(0);

  const getValueRangeItem = (value, item) => {
    let color;
    let colorCode;
    let icon;
    let text;
    obsRange?.forEach(
      ({
        range,
        color: rangeColor,
        colorCode: rangeColorCode,
        icon: rangeIcon,
        text: rangeText,
      }) => {
        if (
          Number(value) >= Number(range[0]) &&
          Number(value) <= Number(range[1])
        ) {
          colorCode = rangeColorCode;
          color = rangeColor;
          icon = rangeIcon;
          text = rangeText;
        }
      }
    );
    if (item === 'text') return text;
    if (item === 'icon') return icon;
    if (item === 'colorCode') return colorCode;
    return color || 'black';
  };

  useEffect(() => {
    const obsExtractedRanges = observation?.anomalyResult?.extractedRanges;
    let obsGraphPoints = observation?.dataPoints || [];

    const obsRang = obsExtractedRanges?.map((range) => {
      const formattedStatus = range.status
        ?.replace('Range', '')
        ?.replace(' ', '')
        ?.toLowerCase();

      let subText;

      if (range?.values[0] === '0') subText = '<' + range?.values[1];
      else if (range?.values[1] === '99999') subText = '>' + range?.values[0];
      else subText = `${range.values[0]} - ${range.values[1]}`;

      return {
        text: range?.status,
        range: range?.values,
        subText,
        colorCode: values[formattedStatus]?.colorCode,
        color: values[formattedStatus]?.color,
        icon: values[formattedStatus]?.icon,
      };
    });

    setObsRange(obsRang);

    if (obsGraphPoints.length > 1) {
      let filteredGraphPoints = obsGraphPoints.filter(
        (point) => point?.value !== ''
      );
      if (!filteredGraphPoints?.length) return;
      const uniqueFilteredGraphPoints = uniqBy(filteredGraphPoints, 'date');
      uniqueFilteredGraphPoints.sort(
        (a, b) => moment(a.date).unix() - moment(b.date).unix()
      );

      setGraphPoints(uniqueFilteredGraphPoints);
      if (observation?.unit && observation?.unit !== 'NA')
        setGraphUnit(observation?.unit);

      setLatest(
        Number(uniqueFilteredGraphPoints?.at(-1)?.value || 0)?.toFixed(2)
      );
      const valuesToPlotSum = uniqueFilteredGraphPoints?.reduce(
        (a, p) => Number(a) + Number(p.value || 0),
        0
      );
      if (uniqueFilteredGraphPoints?.length > 0) {
        setAverage(
          Number(valuesToPlotSum / uniqueFilteredGraphPoints?.length)?.toFixed(
            2
          )
        );
      }
    }
  }, []);

  const renderLegendRow = (obs) => {
    return (
      <div className="bio-sec__legend__row">
        {/* <img
          src={
            values[
              obs.status.replace('Range', '').replace(' ', '').toLowerCase()
            ]?.icon
          }
          alt="high"
          className="bio-sec__legend__row__icon"
        /> */}
        <div
          className={`bio-sec__legend__row__status bio-sec__legend__row__status--${
            values[
              String(obs.status)
                .replace('Range', '')
                .replace(' ', '')
                .toLowerCase()
            ]?.color
          }`}
        >
          {obs.status}
        </div>
        <div className="bio-sec__legend__row__range">
          {obs?.values[1] !== '99999' && obs?.values[0]}
          {obs?.values[1] === '99999'
            ? `> ${obs?.values[0]}`
            : ` - ${obs?.values[1]}`}
        </div>
        {observation.unit}
      </div>
    );
  };

  return (
    <div className="bio-sec">
      <div className="bio-sec__visual-wrapper">
        <div className="bio-sec__visual">
          {graphPoints?.length > 1 && !isNaN(graphPoints[0]?.value) ? (
            <div
              className={`hvr hvr--light hvr--${getValueRangeItem(
                average,
                'text'
              )}`}
            >
              <ResultLineGraph
                graphPoints={graphPoints}
                graphUnit={graphUnit}
                latest={latest}
                average={average}
                range={obsRange}
                getValueRangeItem={getValueRangeItem}
                smallTexts
                canvasPadding={{ top: 20, left: 5 }}
                observation={observation}
                title={observation?.display}
              />
            </div>
          ) : (
            <HealthValueRange
              isLight
              key={observation.observation_id}
              observation={observation}
            />
          )}
        </div>
        <div className="bio-sec__legend">
          <div className="bio-sec__legend__header">Biomarker Legend</div>
          {observation?.anomalyResult?.extractedRanges?.map((obs) => (
            <div className="mt-1 mb-1">{renderLegendRow(obs)}</div>
          ))}
        </div>
      </div>
      {!!observation.description && (
        <div className="bio-sec__info">
          <img
            className="bio-sec__info__icon"
            src={infoBubbleSlantSvg}
            alt="icon"
          />
          <div className="bio-sec__info__text">
            <div className="bio-sec__info__header">
              What is {observation.display}
            </div>
            {observation.description}
          </div>
        </div>
      )}
      {observation?.anomalyResult?.status?.toLowerCase() !== 'normal' &&
        !!observation.status_description && (
          <div
            className={`bio-sec__info bio-sec__info--${observation?.anomalyResult?.status}`}
          >
            <img
              className="bio-sec__info__icon"
              src={
                // Yo alfred check this out
                values[
                  String(observation?.anomalyResult?.status)
                    .replace('Range', '')
                    .replace(' ', '')
                    .toLowerCase()
                ].icon
              }
              alt="icon"
            />
            <div className="bio-sec__info__text">
              <div className="bio-sec__info__header">
                <>
                  What does it mean to have{' '}
                  {observation?.anomalyResult?.status.toLowerCase()}{' '}
                  {observation.display.split('[')[0]}?
                </>
              </div>
              {observation.status_description.replace(
                'Discuss your test result with your doctor and find out what it means for your health.',
                ''
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default BiomarkerSection;
