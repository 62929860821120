import Filters from './Filter';
import Actions from './Actions';

import { Search } from 'components';
import { Label } from 'semantic-ui-react';

const SearchBar = ({
  hideUpload,
  isPatientApp,
  isDownloading,
  toggleModal,
  togglePdfModals,
  selectedRows,
  setShowDeleteResultsModal,
  setToggleuploadReport,
  filterDropdownRef,
  isShowFilters,
  updateSearchParams,
  setSearchParams,
  searchParams,
  clinicOptions,
  selectedClinics,
  setSelectedClinics,
  isSearching,
  isMobile,
  searchValue,
  onSearchChange,
  setShowFilters,
  markAsRead,
  showActions = true,
  totalResults,
}) => {
  return (
    <div className="results-table__search">
      <div className="results-table__search__section">
        {showActions && (
          <Actions
            markAsRead={markAsRead}
            hideUpload={hideUpload}
            isPatientApp={isPatientApp}
            isDownloading={isDownloading}
            toggleModal={toggleModal}
            togglePdfModals={togglePdfModals}
            selectedRows={selectedRows}
            setShowDeleteResultsModal={setShowDeleteResultsModal}
            setToggleuploadReport={setToggleuploadReport}
          />
        )}
      </div>
      {totalResults > 0 ? (
        <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
          <Label size="large">Total: {totalResults}</Label>
        </div>
      ) : (
        ''
      )}
      <div className="results-table__search__section">
        {!isPatientApp && (
          <Filters
            filterDropdownRef={filterDropdownRef}
            isShowFilters={isShowFilters}
            updateSearchParams={updateSearchParams}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            clinicOptions={clinicOptions}
            selectedClinics={selectedClinics}
            setSelectedClinics={setSelectedClinics}
            setShowFilters={setShowFilters}
          />
        )}
        <Search
          isLoading={isSearching}
          size={isMobile ? 'fullWidth' : 'large'}
          value={searchParams.searchTerm}
          dropdown={false}
          onSearchChange={(e, { value }) => onSearchChange(value)}
          isFilter={true}
        />
      </div>
    </div>
  );
};

export default SearchBar;
