import React, { useState, useRef, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Sidebar, Menu, Icon as SIcon } from 'semantic-ui-react';
import { signOutUser } from 'auth/authentication';

import { SideBarMenu, Text } from '../../components';
import history from '../../history';

import ResultsIcon from '../../assets/new/results';
import PatientsIcon from '../../assets/new/patients';
import AppointmentsIcon from '../../assets/new/calendar';
import DocumentIcon from '../../assets/new/documents';
import StaffIcon from '../../assets/new/staff';
import SettingsIcon from '../../assets/new/settings';
import TestOrderIcon from '../../assets/new/testOrder';
import ReactHealthIcon from '../../assets/new/reactHealth';
import MentalHealthIcon from '../../assets/new/mentalHealth';
import SamplesIcon from '../../assets/new/testTubes';
import signoutSvg from '../../assets/new/signout.svg';
import editSvg from '../../assets/new/edit.svg';
import HealthIcon from 'assets/new/health';

import './sideBar.scss';

const SideBar = (props) => {
  const splitPath = props.location.pathname.split('/');

  const sidebarRef = useRef();
  // const sidebarToggleButtonRef = useRef();
  const subMenuRef = useRef();

  const [isSidebarToggled, setIsSidebarToggled] = useState(false);
  const [selectedTab, setSelectedTab] = useState('/results');
  const [subMenuItems, setSubMenuItems] = useState(false);

  const {
    user: userInfo,
    clientInformation,
    showSideBar,
    customUrlClient,
  } = useSelector((state) => state.general);

  // const toggleSidebar = e => {
  //   setIsSidebarToggled(!isSidebarToggled);
  // };

  const signUserOut = () => {
    signOutUser(true);
  };

  useEffect(() => {
    const getSelectedTab = () => {
      if (splitPath[1] === 'results') return '/results';
      if (splitPath[1] === 'insights') return '/insights';
      if (splitPath[1] === 'calendar') return '/calendar';
      if (splitPath[1] === 'certificates') return '/certificates';
      if (splitPath[1] === 'course') return '/course/planned';
    };
    setSelectedTab(getSelectedTab());

    const handleClick = (e) => {
      if (
        sidebarRef.current.contains(e.target) ||
        // sidebarToggleButtonRef.current.contains(e.target) ||
        (subMenuRef.current && subMenuRef.current.contains(e.target))
      ) {
        return;
      }
      setIsSidebarToggled(false);
    };
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []); // eslint-disable-line

  const navigationItems = [
    {
      id: 'main.results_inbox',
      title: 'Results Inbox',
      icon: ResultsIcon,
      urlKey: '/results',
    },
    {
      // id: 'main.results_inbox',
      title: 'Health',
      icon: HealthIcon,
      urlKey: '/health',
    },
    {
      id: 'main.patients',
      title: 'Patients',
      icon: PatientsIcon,
      urlKey: '/patients',
    },
    {
      id: 'main.documents',
      title: 'Documents',
      icon: DocumentIcon,
      urlKey: '/documents',
      redirect: false,
      isHide:
        customUrlClient === 'sIdzwYsvwehphIHNG2ce' ||
        !clientInformation?.documents?.length,
      subMenu: [
        {
          id: 'main_sub_menu.new_document',
          label: 'New Document',
          path: '/documents/issue',
        },
        {
          id: 'main_sub_menu.issued_documents',
          label: 'Issued Documents',
          path: '/documents/issued',
        },
        {
          id: 'main_sub_menu.templates',
          label: 'Templates',
          path: '/documents/templates',
          // role: {
          //   superAdmin: true,
          //   clinicAdmin: true,
          //   instructor: true,
          //   appointmentHandler: false,
          // },
        },
      ],
    },
    {
      id: 'main.appointments',
      title: 'Appointments',
      icon: AppointmentsIcon,
      urlKey: '/appointments',
      redirect: false,
      isHide: customUrlClient === 'sIdzwYsvwehphIHNG2ce',
      subMenu: [
        {
          id: 'main_sub_menu.new_appointment',
          label: 'New Appointment',
          path: '/appointments/overview/schedule',
        },
        {
          id: 'main_sub_menu.overview',
          label: 'Overview',
          path: '/appointments/overview',
        },
        {
          id: 'main_sub_menu.calendar',
          label: 'Calendar',
          path: '/appointments/calendar',
        },
        {
          id: 'main_sub_menu.statistics',
          label: 'Statistics',
          path: '/appointments/stats',
        },
        {
          id: 'main_sub_menu.settings',
          label: 'Settings',
          path: '/appointments/settings',
        },
      ],
    },
    {
      title: 'Mental Health',
      icon: MentalHealthIcon,
      urlKey: '/mental-health',
      isHide: !clientInformation?.hasMentalHealthPEME,
    },
    {
      id: 'main.react_health',
      title: 'ReACT Health',
      icon: ReactHealthIcon,
      urlKey: '/react-health',
      isHide:
        customUrlClient === 'sIdzwYsvwehphIHNG2ce' &&
        !clientInformation.hasSmartReport,
    },
    {
      // id: 'main.react_health',
      title: 'Consumables request',
      icon: SamplesIcon,
      urlKey: '/consumable-req',
      // isHide: customUrlClient === 'sIdzwYsvwehphIHNG2ce' && !clientInformation.hasSmartReport,
      isHide: customUrlClient !== 'sIdzwYsvwehphIHNG2ce',
    },
    {
      id: 'main.staff',
      title: 'Staff',
      icon: StaffIcon,
      urlKey: '/staff',
      isHide: customUrlClient === 'sIdzwYsvwehphIHNG2ce',
    },
    {
      id: 'main.sample_collection',
      title: 'Sample Collection',
      icon: TestOrderIcon,
      urlKey: '/sample-collection',
      redirectUrl: '/sample-collection',
      isHide: customUrlClient !== 'sIdzwYsvwehphIHNG2ce',
    },
    {
      id: 'main.test_order',
      title: 'Test Order',
      icon: TestOrderIcon,
      urlKey: '/test-order',
      redirectUrl: '/test-order/overview',
      // isHide: customUrlClient === 'sIdzwYsvwehphIHNG2ce',
      isHide: !clientInformation.hasTestOrder,
    },
    // {
    //   title: 'Sample Collection',
    //   icon: SamplesIcon,
    //   urlKey: '/sample-collection',
    // },
    // {
    //   title: 'Webshop',
    //   icon: WebShopIcon,
    //   urlKey: '/webshop',
    // TODO: If client is Riverr show submenu
    // subMenu: [
    //   {
    //     label: 'Content Management',
    //     path: '/webshop',
    //     // role: {
    //     //   superAdmin: true,
    //     //   clinicAdmin: true,
    //     //   instructor: true,
    //     //   appointmentHandler: false,
    //     // },
    //   },
    //   {
    //     label: 'Deployments',
    //     path: '/webshop/deployments',
    //     // role: {
    //     //   superAdmin: true,
    //     //   clinicAdmin: true,
    //     //   instructor: true,
    //     //   appointmentHandler: false,
    //     // },
    //   },
    // ]
    // },
    {
      id: 'main.settings',
      title: 'Settings',
      icon: SettingsIcon,
      urlKey: '/settings',
      // role: { admin: true, superAdmin: true }
    },
    // {
    //   title: 'Results Inbox ( old )',
    //   icon: ResultsIcon,
    //   urlKey: '/results',
    // },
    // {
    //   title: 'Patient & History ( old )',
    //   icon: PatientsIcon,
    //   urlKey: '/patients',
    // },
  ];

  const containerClassNames = classNames('side-bar__container', {
    'side-bar__container--open': showSideBar,
    'side-bar__container--closed': !showSideBar,
  });

  const selectMenuItem = (item) => {
    item.subMenu && setSubMenuItems(item.subMenu);
    !item.subMenu && setSubMenuItems([]);
    if (typeof item.redirect !== 'undefined') return;
    // setSelectedTab(item.redirectUrl || item.urlKey);
    props.history.push(item.redirectUrl || item.urlKey);
  };

  // TODO: move this to useEffect
  const renderItems = useCallback(() => {
    let items = [];

    if (
      userInfo?.email?.includes('@riverr.ai') ||
      clientInformation?.clientId === 'zHAzw9fw2H4cGE8udj50'
    ) {
      const item = {
        title: 'Accounts',
        icon: 'settings',
        urlKey: '/create-account',
        clientRole: { full: true, lab: true },
        type: 'medical',
      };

      navigationItems.push(item);
    }

    navigationItems.forEach((item, index) => {
      if (
        !item.isHide &&
        (!item.role || item.role[userInfo.role]) &&
        (!item.clientRole || item.clientRole[clientInformation.role]) &&
        (!item.type || item.type === clientInformation.type)
      )
        items.push(
          <div
            className={
              `/${splitPath[1]}` === item.urlKey
                ? item.subMenu
                  ? `/${splitPath[1]}` === item.urlKey
                    ? 'side-bar__item side-bar__item--selected'
                    : 'side-bar__item side-bar__item--selected-nested'
                  : 'side-bar__item side-bar__item--selected'
                : `/${splitPath[1]}` === item.urlKey
                ? 'side-bar__item side-bar__item--selected'
                : 'side-bar__item'
            }
            key={item.urlKey}
            onClick={() => selectMenuItem(item)}
          >
            <div className="side-bar__item__icon-wrapper">
              <div className="side-bar__item__icon-wrapper__icon">
                <item.icon
                  color={
                    splitPath[1] === item.urlKey
                      ? item.subMenu
                        ? `/${splitPath[1]}` === item.urlKey
                          ? '#fff'
                          : '#6f6f6f'
                        : '#fff'
                      : `/${splitPath[1]}` === item.urlKey
                      ? '#fff'
                      : '#6f6f6f'
                  }
                />
              </div>
              <Text
                className="side-bar__item__title"
                bold
                id={item.id}
                color={
                  splitPath[1] === item.urlKey
                    ? item.subMenu
                      ? `/${splitPath[1]}` === item.urlKey
                        ? '#fff'
                        : '#6f6f6f'
                      : '#fff'
                    : `/${splitPath[1]}` === item.urlKey
                    ? '#fff'
                    : '#6f6f6f'
                }
              >
                {item.title}
              </Text>
            </div>
          </div>
        );
    });

    return items;
  }, [clientInformation, userInfo, selectedTab, history.location.pathname]);

  return (
    <Sidebar.Pushable as="section">
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        vertical
        visible={showSideBar}
      >
        <div className="side-bar">
          <div className={containerClassNames} ref={sidebarRef}>
            <div className="side-bar__content">{renderItems()}</div>
            <div className="side-bar__bottom-content">
              <div
                className="side-bar__item"
                onClick={() => props.setIsModalToggled(true)}
              >
                <div className="side-bar__item__icon-wrapper">
                  <img
                    className="side-bar__item__icon-wrapper__icon mb-1"
                    src={editSvg}
                  />
                  <Text
                    className="side-bar__item__title"
                    bold
                    color="#6f6f6f"
                    id="forget_pw.reset_password"
                  >
                    Reset password
                  </Text>
                </div>
              </div>
              <div className="side-bar__item" onClick={() => signUserOut()}>
                <div className="side-bar__item__icon-wrapper">
                  <img
                    className="side-bar__item__icon-wrapper__icon mb-1"
                    src={signoutSvg}
                  />
                  <Text className="side-bar__item__title" bold color="#6f6f6f">
                    Log out
                  </Text>
                </div>
              </div>
            </div>
          </div>
          {subMenuItems.length > 0 && (
            <div className="side-bar__sub-menu">
              <SideBarMenu
                clientType={clientInformation.type}
                role={userInfo.role}
                subMenuRef={subMenuRef}
                sidebarRef={sidebarRef}
                history={props.history}
                items={subMenuItems}
                closeMenu={() => setSubMenuItems([])}
                location={history.location}
                setIsSidebarToggled={setIsSidebarToggled}
              />
            </div>
          )}
        </div>
      </Sidebar>
    </Sidebar.Pushable>
  );
};

export default withRouter(SideBar);
