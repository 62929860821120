import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { uniqBy } from 'lodash';

import { Text } from 'components';
import { momentSg } from '../../../../../helpers/dateTime';
import ResultLineGraph from '../../lineGraph/ResultLineGraph';
import HealthValueLine from 'features/HealthValueLine/HealthValueLine';
import {
  getObservationRange,
  getValueRangeItem,
} from 'api/fhir/observation/observationMapper';

import './tableRow.scss';

const TableRow = ({ observation, graphData, values, onRowClick }) => {
  const [obsRange, setObsRange] = useState(null);
  const [graphPoints, setGraphPoints] = useState(null);
  const [graphUnit, setGraphUnit] = useState(null);
  const { user: userInfo } = useSelector((state) => state.general);
  const [average, setAverage] = useState(0);
  const [latest, setLatest] = useState(0);
  const isValueNaN = Number.isNaN(Number(observation?.value));

  useEffect(() => {
    const obsExtractedRanges = observation?.anomalyResult?.extractedRanges;
    let obsGraphPoints = observation?.dataPoints || [];
    const obsRang = getObservationRange(obsExtractedRanges);
    setObsRange(obsRang);

    if (obsGraphPoints.length > 1) {
      setGraphPoints(obsGraphPoints);
      if (observation?.unit && observation?.unit !== 'NA')
        setGraphUnit(observation?.unit);

      setLatest(Number(obsGraphPoints?.at(-1)?.value || 0)?.toFixed(2));
      const valuesToPlotSum = obsGraphPoints?.reduce(
        (a, p) => Number(a) + Number(p.value || 0),
        0
      );
      if (obsGraphPoints?.length > 0) {
        setAverage(
          Number(valuesToPlotSum / obsGraphPoints?.length)?.toFixed(2)
        );
      }
    }
  }, []);

  const getValueRangeItemWithObsRange = (value, item) => {
    return getValueRangeItem(value, item, obsRange);
  };

  let observationStatusClass = observation?.anomalyResult?.status
  if(observationStatusClass === 'Critical high') {
    observationStatusClass = 'High';
  }
  return (
    <Table.Row
      key={observation.id}
      onClick={() => onRowClick(observation)}
      className={`obs-table__row obs-table__row--${
        graphPoints?.length > 1 && !!obsRange?.length
          ? getValueRangeItemWithObsRange(latest, 'text')
          : observationStatusClass
      }`}
    >
      <Table.Cell style={{ width: '100px' }}>
        <Text size="small" uppercase className={'obs-code'}>
          {observation.code}
        </Text>
      </Table.Cell>
      <Table.Cell style={{ width: '250px' }}>
        <Text bold>{observation.display}</Text>
        {(userInfo.email === 'cuong@riverr.ai' ||
          // userInfo.email === 'william@riverr.ai' ||
          userInfo.email === 'dan@riverr.ai') && (
          <Text size="small">{observation.id}</Text>
        )}
      </Table.Cell>
      <Table.Cell>
        <div className="obs-table__row__value-status__table">
          {graphPoints?.length > 1 && !!obsRange?.length ? (
            <ResultLineGraph
              graphPoints={graphPoints}
              unit={graphUnit}
              latest={latest}
              average={average}
              range={obsRange}
              getValueRangeItem={getValueRangeItemWithObsRange}
              canvasPadding={{ top: 20, left: 5 }}
              title={observation.display}
            />
          ) : (
            <div className="obs-table__row__value-status">
              <div className="obs-table__row__value">
                {values[
                  observation?.anomalyResult?.status
                    ?.replace('Range', '')
                    ?.replace(' ', '')
                    ?.toLowerCase() || 'abnormal'
                ]?.icon && (
                  <img
                    className="obs-table__icon"
                    src={
                      values[
                        observation?.anomalyResult?.status
                          ?.replace('Range', '')
                          ?.replace(' ', '')
                          ?.toLowerCase() || 'abnormal'
                      ].icon
                    }
                    alt="icon"
                  />
                )}
                <div className="obs-table__row__value__text">
                  {(!!observation?.value || observation?.value === 0) && (
                    <Text dark className={'obs-value'}>
                      {String(observation?.value)
                        ?.replace('&amp;', '&')
                        ?.replace('&Amp;', '&')
                        ?.replace('&lt;', '<')
                        ?.replace('&Lt;', '<')
                        ?.replace('&gt;', '>')
                        ?.replace('&Gt;', '>')}
                    </Text>
                  )}
                  {observation?.unit && observation?.unit !== 'NA' && (
                    <Text size="tiny">{observation?.unit}</Text>
                  )}
                </div>
              </div>

              {isValueNaN && !obsRange?.length && (
                <div className="obs-table__row__status">
                  <Text center bold>
                    {observation.anomalyResult?.status}
                  </Text>
                </div>
              )}

              {isValueNaN && obsRange?.length > 0 && (
                <div className="obs-table__row__range">
                  <HealthValueLine
                    className="obs-table__row__line"
                    key={observation?.id}
                    value={{
                      value: observation.value,
                      text: observation?.status,
                    }}
                    ranges={obsRange}
                  />
                </div>
              )}

              {!isValueNaN && obsRange?.length > 0 && (
                <div className="obs-table__row__range">
                  <HealthValueLine
                    className="obs-table__row__line"
                    key={observation?.id}
                    value={{
                      value: observation?.value,
                      text: observation?.status,
                    }}
                    ranges={obsRange}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Table.Cell>
      {/* <Table.Cell>
        {observation?.anomalyResult?.extractedRanges?.length > 0 && (
          <>
            {observation?.anomalyResult?.extractedRanges?.map((data) => (
              <div style={{ display: 'flex' }}>
                <Text size="tiny">{data.status}:</Text>&nbsp;
                <Text size="tiny" bold>
                  {' '}
                  {data.values[0]}
                  {data.values[1] === 99999 ? ' <' : ` - ${data.values[1]}`}
                </Text>
              </div>
            ))}
          </>
        )}
      </Table.Cell> */}
      <Table.Cell style={{ width: '100px' }}>
        <Text size="small">
          {graphPoints?.length > 1 && !!obsRange?.length ? (
            momentSg(graphPoints[graphPoints.length - 1].date).format(
              'D MMM YY, HH:mm'
            )
          ) : (
            <>
              {observation.effectiveDateTime &&
                momentSg(observation.effectiveDateTime).format(
                  'D MMM YY, HH:mm'
                )}
            </>
          )}
        </Text>
      </Table.Cell>
    </Table.Row>
  );
};

export default TableRow;
