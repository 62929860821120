import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { uniqBy } from 'lodash';

import { Text } from 'components';
import { momentSg } from '../../../../helpers/dateTime';
import ResultLineGraph from '../lineGraph/ResultLineGraph';
import HealthValueLine from 'features/HealthValueLine/HealthValueLine';
import { getObservationRange, getValueRangeItem } from 'api/fhir/observation/observationMapper';

const TableRow = ({ observation, graphData, values, onRowClick }) => {
  const [obsRange, setObsRange] = useState(null);
  const [graphPoints, setGraphPoints] = useState(null);
  const [graphUnit, setGraphUnit] = useState(null);
  const { user: userInfo } = useSelector((state) => state.general);
  const [average, setAverage] = useState(0);
  const [latest, setLatest] = useState(0);


  const getValueRangeItemWithObsRange = (value, item) => {
    return getValueRangeItem(value, item, obsRange);
  };

  useEffect(() => {
    const obsExtractedRanges = observation?.anomalyResult?.extractedRanges;
    let obsGraphPoints = observation?.dataPoints || [];

    const obsRang = getObservationRange(obsExtractedRanges);
    setObsRange(obsRang);

    if (obsGraphPoints.length > 1) {

      setGraphPoints(obsGraphPoints);
      if (observation?.unit && observation?.unit !== 'NA')
        setGraphUnit(observation?.unit);

      setLatest(Number(obsGraphPoints?.at(-1)?.value || 0)?.toFixed(2));
      const valuesToPlotSum = obsGraphPoints?.reduce(
        (a, p) => Number(a) + Number(p.value || 0),
        0
      );
      if (obsGraphPoints?.length > 0) {
        setAverage(
          Number(valuesToPlotSum / obsGraphPoints?.length)?.toFixed(2)
        );
      }
    }
  }, []);

  return (
    <div
      key={observation.id}
      onClick={() => onRowClick(observation)}
      className={`m-obs__row m-obs__row--${
        graphPoints?.length > 1 && !!obsRange.length
          ? getValueRangeItemWithObsRange(latest, 'text')?.toLowerCase()
          : observation?.anomalyResult?.status?.toLowerCase()
      }`}
    >
      <Text size="small" uppercase>
        {observation.code}
      </Text>
      <div className="m-obs__row__title">
        <Text size="bigish" bold>
          {observation.display}
        </Text>
        <div className="m-obs__row__value">
          <div className="m-obs__row__value__text">
            {values[
              observation?.anomalyResult?.status
                ?.toLowerCase()
                ?.replace('Range', '')
                ?.replace(' ', '')
                ?.toLowerCase()
            ]?.icon && (
              <img
                className="m-obs__icon"
                src={
                  values[
                    observation?.anomalyResult?.status
                      ?.toLowerCase()
                      ?.replace('Range', '')
                      ?.replace(' ', '')
                      ?.toLowerCase()
                  ].icon
                }
                alt="icon"
              />
            )}
            {(!!observation?.value || observation?.value === 0) && (
              <Text size="big" dark uppercase>
                {String(observation?.value)
                  ?.replace('&amp;', '&')
                  ?.replace('&Amp;', '&')
                  ?.replace('&lt;', '<')
                  ?.replace('&Lt;', '<')
                  ?.replace('&gt;', '>')
                  ?.replace('&Gt;', '>')}
              </Text>
            )}
          </div>
          {observation?.unit && observation?.unit !== 'NA' && (
            <Text size="tiny" uppercase>
              {observation?.unit}
            </Text>
          )}
        </div>
      </div>
      <div className="m-obs__row__value-status__table">
        {graphPoints?.length > 1 && !!obsRange.length ? (
          <ResultLineGraph
            graphPoints={graphPoints}
            graphUnit={graphUnit}
            latest={latest}
            average={average}
            range={obsRange}
            getValueRangeItem={getValueRangeItemWithObsRange}
            canvasPadding={{ top: 20, left: 5 }}
            title={observation.display}
          />
        ) : (
          <div className="m-obs__row__value-status">
            {Number.isNaN(Number(observation?.value)) || !obsRange ? (
              // <div className="m-obs__row__status">
              //   <Text center bold>
              //     {observation.anomalyResult?.status}
              //   </Text>
              // </div>
              <></>
            ) : (
              <div className="m-obs__row__range">
                <HealthValueLine
                  showValue={false}
                  className="m-obs__row__line"
                  key={observation?.id}
                  value={{
                    value: observation?.value,
                    text: observation?.status,
                  }}
                  ranges={obsRange}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Text size="tinniest" className="m-obs__row__date" color="lightGrey">
        Reported on{' '}
        {observation.effectiveDateTime &&
          momentSg(observation.effectiveDateTime).format('D MMM YYYY')}
      </Text>
    </div>
  );
};

export default TableRow;
