import axiosInstance from '../extractorAxiosInstance';

async function uploadPdf(formData) {
  try {
    const res = await axiosInstance.post('/pdf-extractor/nativePdfToData', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function uploadMultiPdf(formData) {
  const res = await axiosInstance.post('/pdf-extractor/multipleUploadNativePdfToData', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'accept': 'application/json'
    },
  });
  const { response_code, message } = res.data;
  return {
    response_code,
    bundle: message,
  };
}

async function uploadMultiPdfV2(formData) {
  const res = await axiosInstance.post(
    '/pdf-extractor/multipleUploadNativePdfToData_v2',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    }
  );
  return res.data;
}

const extractorApi = {
  uploadPdf,
  uploadMultiPdf,
  uploadMultiPdfV2,
};
export default extractorApi;
